import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Popconfirm} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';

import {
    EditOutlined,
    DeleteOutlined,
    LeftOutlined,
    PlusOutlined,
    CheckOutlined,
    Loading3QuartersOutlined,
    CloseOutlined
} from "@ant-design/icons";

const SubTopic = (props) => {
    let params = useParams()
    let query = []
    let topic = params.id !== "add" ? params.id : false;
    query["topic"] = topic;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [loading, setLoading] = useState(false)
    let [isSmall, ] = useGlobal('isSmall')

    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms");
    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'created';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        let restData = await api.get(`/rest/topicquestions?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            setLoading(false)
            setTotalCount(data.result.total);
            return data.result.rows.map((item, key) => {
                item.ownerName=item.createdBy ? item.createdBy.name : ""
                item.ownerLastname=item.createdBy ? item.createdBy.lastname : ""
                if (item.active)
                    item.active = <CheckOutlined/>;
                else
                    item.active = <CloseOutlined/>;
                item.key = key;
                return item;
            })
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

    let deleteRow = async (item_id) => {
        api.delete(`/rest/topicquestions/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/topicquestions/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }
    function turkishSort(a, b) {
        var alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
        if (a.length === 0 || b.length === 0) {
            return a.length - b.length;
        }
        for (var i = 0; i < a.length && i < b.length; i++) {
            var ai = alfabe.indexOf(a[i]);
            var bi = alfabe.indexOf(b[i]);
            if (ai !== bi) {
                return ai - bi;
            }
        }
    }

    let columns = [
        {
            title: languagesCms.NAME,
                  dataIndex: 'ownerName',
                  key: 'ownerName',
                  sorter: (a, b) => turkishSort(a.ownerName, b.ownerName),
                  sortDirections: ['descend', 'ascend']
              }, {
            title: languagesCms.LASTNAME,
                  dataIndex: 'ownerLastname',
                  key: 'ownerLastname',
                  sorter: (a, b) => turkishSort(a.ownerLastname, b.ownerLastname),
                  sortDirections: ['descend', 'ascend']
              },
        {
            title:languagesCms.QUESTION,
            dataIndex: 'question',
            key: 'question',
            sorter: (a, b) => a.question - b.question,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.LIKE_COUNT,
            dataIndex: 'likeCount',
            key: 'likeCount',
            sorter: (a, b) => a.likeCount - b.likeCount,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.FAKE_LIKE_COUNT,
            dataIndex: 'fakeLikeCount',
            key: 'fakeLikeCount',
            sorter: (a, b) => a.fakeLikeCount - b.fakeLikeCount,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/topics/detail/" + topic + "/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? "" : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? "" : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to={"/topics/detail/" + topic + "/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                    <Link to="/topics">
                        <Button type="light" icon={<LeftOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Table dataSource={data} columns={columns}
                       onChange={handleTableChange}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};

export default SubTopic;
